/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    transform: translateZ(0);
    backface-visibility: hidden;
    transition: transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        transform: scale(1.1);
        transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        transform: translateZ(0);
        backface-visibility: hidden;
        transition: transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        transform: scale(1.1);
        transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }
